'use client'

// React Imports
import React, {useEffect, useState} from 'react'

// Next Imports
import Link from 'next/link'
import {useParams, useRouter, useSearchParams} from 'next/navigation'

// MUI Imports
import useMediaQuery from '@mui/material/useMediaQuery'
import {styled, useTheme} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'
import Fade from '@mui/material/Fade';

// Third-party Imports
import {signIn, useSession} from 'next-auth/react'
import {Controller, useForm} from 'react-hook-form'
import {valibotResolver} from '@hookform/resolvers/valibot'
import type {SubmitHandler} from 'react-hook-form'
import {email, object, minLength, string, pipe, nonEmpty} from 'valibot'
import type {InferInput} from 'valibot'
import classnames from 'classnames'

// Type Imports
import type {Locale} from '@/configs/i18n'

// Component Imports
import Logo from '@components/layout/shared/Logo'
import CustomTextField from '@core/components/mui/TextField'

// Config Imports
import themeConfig from '@configs/themeConfig'

// Hook Imports
import {useImageVariant} from '@core/hooks/useImageVariant'
import {useSettings} from '@core/hooks/useSettings'

// Util Imports
import {getLocalizedUrl} from '@/utils/i18n'
import {useTranslation} from 'react-i18next';
import Smile from '@assets/svg/Smile'
import LanguageDropdown from "@components/layout/shared/LanguageDropdown";
import Swal from "sweetalert2";

type ErrorType = {
    message: string[]
}

type FormData = InferInput<typeof schema>

const schema = object({
    email: pipe(string(), minLength(1, 'This field is required'), email('Email is invalid')),
    password: pipe(
        string(),
        nonEmpty('This field is required'),
        minLength(5, 'Password must be at least 5 characters long')
    )
})

type Props = {
    t: (key: string) => string;
    handleSwitch: (value: boolean) => void;
};

const Login: React.FC<Props> = ({handleSwitch, t}) => {
    // States
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const [errorState, setErrorState] = useState<ErrorType | null>(null)
    const [errorEmail, setErrorEmail] = useState<any>({
        status: false,
        msg: ''
    })
    const [errorPassword, setErrorPassword] = useState<any>({
        status: false,
        msg: ''
    })


    // Hooks
    const router = useRouter()
    const searchParams = useSearchParams()
    const {lang: locale} = useParams()
    const {data: session} = useSession();
    const [isSending, setIsSending] = useState(false)

    // const {t} = useTranslation(locale);

    const {
        control,
        handleSubmit,
        formState: {errors}
    } = useForm<FormData>({
        resolver: valibotResolver(schema),
        defaultValues: {
            email: '',
            password: ''
        }
    })

    useEffect(() => {
        if (session) {
            let mySession: any = session
            window.localStorage.setItem('userData', JSON.stringify(mySession?.user))
        }
    }, [session]);


    const handleClickShowPassword = () => setIsPasswordShown(show => !show)


    const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
        setIsSending(true)
        const res = await signIn('credentials', {
            email: data.email,
            password: data.password,
            redirect: false,
            locale: locale
        })

        if (res && res.ok && res.error === null) {
            setIsSending(false)

            // Vars
            const redirectURL = '/auth'
            router.push(getLocalizedUrl(redirectURL, locale as Locale))
        } else {
            if (res?.error && res.error !== 'fetch failed') {
                console.log(res.error)
                const error = JSON.parse(res.error)
                console.log(error)

                if (error.codice === 404 || error?.message.includes("email")) {
                    setErrorEmail({status: true, msg: (error?.msg || error.message)})
                    setErrorPassword({status: false, msg: ''})
                } else if (error.codice === 401 || error?.message.includes("password")) {
                    setErrorEmail({status: false, msg: ''})
                    setErrorPassword({status: true, msg: (error?.msg || error.message)})
                } else if(error.statusCode === 409){
                    setErrorEmail({status: true, msg: t('email-not-verified')})
                    setErrorPassword({status: false, msg: ''})
                } else if(error.statusCode === 201){
                    setErrorEmail({status: true, msg: t('email-not-verified')})
                    setErrorPassword({status: false, msg: t('invalidPass')})
                }else {
                    setErrorEmail({status: true, msg: t('invalidEmail')})
                    setErrorPassword({status: true, msg: t('invalidPass')})
                }
                setIsSending(false)
            }else{
                Swal.fire({
                    icon: "error",
                    title: `${t("errore-axios")}`,
                    html: `${t("errore-axios-text")}`,
                    showConfirmButton: false,
                    showDenyButton: true,
                    denyButtonText: t("close"),
                })
                setIsSending(false)
            }
        }
    }

    return (
        <div
            className='flex flex-col gap-6 is-full sm:is-full md:is-full md:max-is-[unset] mbs-8 sm:mbs-11 md:mbs-0'>
            <div className='flex flex-col gap-1'>
                <Typography variant='h5'>{t('loginBenvenuto')}</Typography>
                <div className='flex flex-row items-center'>
                    <Typography variant='h5'
                                style={{marginRight: '5px'}}>{t('loginBenvenutoDescr') + themeConfig.templateName}!
                    </Typography>
                    <div className='flex flex-row items-center'>
                        <Smile/>
                    </div>
                </div>
                <Typography variant='body2'>{t('loginDescrizione')}</Typography>

            </div>

            {/*<div className='flex flex-row-reverse'>*/}
            {/*    <LanguageDropdown/>*/}
            {/*</div>*/}
            <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}
                  className='flex flex-col gap-6'>
                <Controller
                    name='email'
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                        <CustomTextField
                            {...field}
                            // autoFocus
                            fullWidth
                            type='email'
                            label='Email'
                            placeholder={t('inserisciEmail')}
                            onChange={e => {
                                field.onChange(e.target.value)
                                errorState !== null && setErrorState(null)
                                if (!e.target.value) {
                                    setErrorEmail({status: true, msg: 'Inserisci email'})
                                } else {
                                    setErrorEmail({status: false, msg: ''})
                                }
                            }}
                            {...((errors.email || errorEmail.status || errorState !== null) && {
                                error: true,
                                helperText: errorEmail?.msg || ''
                            })}
                        />
                    )}
                />
                <Controller
                    name='password'
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                        <CustomTextField
                            {...field}
                            fullWidth
                            label='Password'
                            placeholder='············'
                            id='login-password'
                            type={isPasswordShown ? 'text' : 'password'}
                            onChange={e => {
                                field.onChange(e.target.value)
                                errorState !== null && setErrorState(null)
                                if (!e.target.value) {
                                    setErrorPassword({status: true, msg: 'Inserisci password'})
                                } else {
                                    setErrorPassword({status: false, msg: ''})
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton edge='end' onClick={handleClickShowPassword}
                                                    onMouseDown={e => e.preventDefault()}>
                                            <i className={isPasswordShown ? 'tabler-eye' : 'tabler-eye-off'}/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}

                            {...((errors.email || errorPassword?.status) && {
                                error: true,
                                helperText: errorPassword.msg
                            })}
                        />
                    )}
                />
                <div className='flex justify-end items-center gap-x-3 gap-y-1 flex-wrap'>
                    {/*<FormControlLabel control={<Checkbox defaultChecked/>} label={t('ricordami')}/>*/}
                    <Typography
                        className='text-end'
                        color='primary'
                        component={Link}
                        // href={getLocalizedUrl('/forgot-password', locale as Locale)}
                        href={'#'}
                        onClick={(event) => {
                            handleSwitch(true)
                        }}

                    >
                        {t('passwordDimenticata')}

                    </Typography>
                </div>
                <div className='flex justify-center items-center flex-wrap gap-2'>
                    <Button disabled={isSending} style={{width: '100%'}} variant='contained' type='submit'>
                        Login
                    </Button>
                </div>
                <div className='flex justify-center items-center flex-wrap gap-2'>
                    <Typography>{t('nuovoDomanda')}</Typography>
                    <Typography component={Link} href={getLocalizedUrl('/Register', locale as Locale)}
                                color='primary'>
                        {t('nuovo')}
                    </Typography>
                </div>
                {/*<Divider className='gap-2'>or</Divider>*/}
                {/*<Button*/}
                {/*  color='secondary'*/}
                {/*  className='self-center text-textPrimary'*/}
                {/*  startIcon={<img src='/images/logos/google.png' alt='Google' width={22} />}*/}
                {/*  sx={{ '& .MuiButton-startIcon': { marginInlineEnd: 3 } }}*/}
                {/*  onClick={() => signIn('google')}*/}
                {/*>*/}
                {/*  Sign in with Google*/}
                {/*</Button>*/}
            </form>
        </div>
    )
}

export default Login
